import { Container, useDisclosure } from "@chakra-ui/react";
import { gql } from "graphql-request";
import { CollegeTrackPLPFragment, PLPSeoSectionFragment } from "@/gql-types";
import { useLongElement } from "@/common/hooks/useLongElement";
import { CardBody, CollapsibleCard } from "@/common/components/Card";
import { FadedBox } from "@/common/components/Faded";
import { RichText } from "@/common/components/RichText";

interface SeoSectionProps {
  seo: PLPSeoSectionFragment["seo"] | CollegeTrackPLPFragment["seo"]["content"];
}

export const SeoSection = ({ seo }: SeoSectionProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const longElement = useLongElement({ maxHeight: 200, callback: onClose }, [seo]);
  return (
    <Container pb="35px" px={[5, null, 4]} maxW="container.xl">
      <CollapsibleCard collapsible={longElement.isLong} collapsed={!isOpen} onCollapseToggle={onToggle}>
        <CardBody>
          <FadedBox
            ref={longElement.ref}
            fadedMaxHeight={longElement.maxHeight}
            fadedPercent={60}
            fade={longElement.isLong && !isOpen}
          >
            <RichText dangerouslySetInnerHTML={{ __html: seo }} />
          </FadedBox>
        </CardBody>
      </CollapsibleCard>
    </Container>
  );
};

SeoSection.fragments = {
  collegeComponent: gql`
    fragment PLPSeoSectionFragment on CollegeComponentObjectType {
      seo
    }
  `,
};
