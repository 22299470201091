import {
  useColorModeValue,
  Link,
  Text,
  Box,
  HStack,
  AspectRatio,
  Img,
  VStack,
  Flex,
  Progress,
  Stack,
  LinkBox,
  Spacer,
  BoxProps,
} from "@chakra-ui/react";
import { Link as NextLink } from "@chakra-ui/next-js";
import { useMemo } from "react";
import { gql } from "graphql-request";
import { persianDigits } from "@/common/utils/string";
import { BuyStateFragment, CollegeCardTrackCollegeFragment, CollegePLPPriceTagFragment } from "@/gql-types";
import { urls } from "@/reverse/urls";
import { CollegeCardLevelColors } from "@/modules/college/plp/common/constants";
import { useSSRBreakpointValue } from "@/common/hooks/useSSRBreakpointValue";
import { Card } from "@/common/components/Card";

interface SelectPriceComponentProps extends CollegePLPPriceTagFragment {}
const SelectPriceComponent = ({ fix_discount_price: fixDiscountPrice, price }: SelectPriceComponentProps) => {
  const discountLine = useColorModeValue("orange.400", "orange.500");
  const priceText = useColorModeValue("gray.700", "gray.200");
  const priceObjectComponent = useMemo(
    () => ({
      hasPayment: (
        <HStack columnGap={1}>
          {fixDiscountPrice && fixDiscountPrice !== price && (
            <Text
              lineHeight="base"
              textDecoration="line-through"
              textDecorationColor={discountLine}
              fontSize="sm"
              color={priceText}
            >
              {persianDigits(price.toLocaleString("fa-IR"))}
            </Text>
          )}
          <Text lineHeight="base" margin="0px !important" fontWeight="bold" fontSize="md" color={priceText}>
            {fixDiscountPrice && fixDiscountPrice !== price
              ? persianDigits((price - fixDiscountPrice).toLocaleString("fa-IR"))
              : persianDigits(price.toLocaleString("fa-IR"))}
          </Text>
          <Text lineHeight="base" margin="0px !important" fontSize="xs" color={priceText}>
            تومان
          </Text>
        </HStack>
      ),
      free: (
        <HStack columnGap={1}>
          {price !== 0 && (
            <Text
              lineHeight="base"
              margin="0px !important"
              textDecoration="line-through"
              textDecorationColor="orange.500"
              fontSize="sm"
              color={priceText}
            >
              {persianDigits(price.toLocaleString("fa-IR"))}
            </Text>
          )}
          <Text lineHeight="base" margin="0px !important" fontWeight="bold" fontSize="md" color={priceText}>
            رایگان
          </Text>
        </HStack>
      ),
    }),
    [priceText, discountLine],
  );

  if (price === 0) {
    return priceObjectComponent.free;
  }
  if (price === fixDiscountPrice) {
    return priceObjectComponent.free;
  }
  if (fixDiscountPrice && fixDiscountPrice !== price) {
    return priceObjectComponent.hasPayment;
  }
  return priceObjectComponent.hasPayment;
};

const BuyState = ({ buy_state: buyState }: BuyStateFragment) => {
  const buyStateBackground = useColorModeValue(`${buyState.colorScheme}.50`, `${buyState.colorScheme}.900`);
  const buyStateTextColor = useColorModeValue(`${buyState.colorScheme}.900`, `${buyState.colorScheme}.50`);

  return (
    <Box height={6} borderRadius="md" px={2} bg={buyStateBackground} display="flex">
      {buyState.icon && (
        <Box w={3.5} h={3.5}>
          <Img objectFit="contain" w="100%" h="100%" src={buyState.icon} />
        </Box>
      )}
      <Text lineHeight="base" color={buyStateTextColor} fontSize={{ base: "xs", md: "sm" }} fontWeight="400">
        {buyState.text}
      </Text>
    </Box>
  );
};

export const TagText = ({ content, ...rest }: TagTextProps) => {
  const tagPrimaryColor = "orange.400";
  return (
    <Box
      _before={{
        content: '""',
        display: "inline-block",
        position: "absolute",
        width: 0,
        height: 0,
        right: "-5px",
        top: 0,
        borderBottomStyle: "solid",
        borderBottomWidth: 4,
        borderBottomColor: tagPrimaryColor,
        borderRight: "solid transparent",
        borderRightWidth: 5,
      }}
      borderStartStartRadius={0}
      borderStartEndRadius={3}
      borderEndStartRadius={5}
      borderEndEndRadius={5}
      position="absolute"
      bg={tagPrimaryColor}
      top={-1}
      py={5}
      right={4}
      zIndex={1}
      width="fit-content"
      {...rest}
    >
      <Box transform="rotate(270deg)" fontWeight="bold" color="gray.800">
        {content}
      </Box>
    </Box>
  );
};

export const CollegeCard = ({
  trackCollege,
  showTag,
}: {
  trackCollege: CollegeCardTrackCollegeFragment;
  showTag: boolean;
}) => {
  const {
    college: { tag_text: tagText },
  } = trackCollege;

  const normalCardImageUrl = useColorModeValue(
    trackCollege.college.card_image_url,
    trackCollege.college.dark_normal_card_image_url,
  );
  const largeCardImageUrl = useColorModeValue(
    trackCollege.college.light_large_card_image_url,
    trackCollege.college.dark_large_card_image_url,
  );

  const isMini = useSSRBreakpointValue([true, null, false]);

  const collegeLevelTextColor = useColorModeValue(
    `${CollegeCardLevelColors[trackCollege.level.level]}.600`,
    `${CollegeCardLevelColors[trackCollege.level.level]}.300`,
  );

  const collegeCardImage = useSSRBreakpointValue([largeCardImageUrl, null, normalCardImageUrl]);

  const minPassedPercent = useSSRBreakpointValue([10, null, 5]);
  const passedPercent = trackCollege.college.user_has_registered
    ? Math.max(trackCollege?.college?.passed_percent, minPassedPercent)
    : 0;

  return (
    <Card
      transition="transform .4s ease"
      _hover={{
        boxShadow: ["unset", null, "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"],
        transform: ["unset", null, "translateY(-4px)"],
      }}
      px={[6, null, 0]}
      py={[4, null, 0]}
    >
      <LinkBox
        padding={{ base: 0, md: 4 }}
        _hover={{ textDecoration: "none" }}
        boxSize="full"
        as={trackCollege.college.has_enabled_new_landpage_for_registered_user ? NextLink : Link}
        href={
          trackCollege.college.has_enabled_new_landpage_for_registered_user
            ? urls["college:college_landpage:landpage_index"](trackCollege.college.pk, trackCollege.college.url_name)
            : urls["college:college_landpage"](trackCollege.college.pk, trackCollege.college.url_name)
        }
        minHeight={{ md: 72 }}
        display="inline-block"
      >
        {!!tagText && showTag && <TagText content={tagText} />}
        <Stack
          direction={{ base: "row", md: "column" }}
          w="full"
          h="full"
          alignItems={{ base: "stretch", md: "start" }}
          justifyContent="space-between"
          spacing={3}
        >
          <VStack width={{ md: "full" }} alignItems={{ base: "stretch", md: "start" }}>
            <AspectRatio
              h={{ base: "full", md: "auto" }}
              minH={{ base: 32, md: "140px" }}
              minW={{ base: 24, md: "full" }}
              zIndex={0}
              ratio={{ base: 0.78, md: 1.7 }}
            >
              <Flex
                direction="column"
                bg="bg.pure"
                minH={{ base: 32, md: "140px" }}
                minW={{ base: 24, md: "auto" }}
                borderRadius={{ base: "lg", md: 12 }}
                border={{ base: "1px solid", md: "0px none" }}
                borderColor={{ base: "border.gray", md: "none" }}
              >
                <Img alt={trackCollege.college.name} boxSize="full" src={collegeCardImage} objectFit="cover" />
                <Progress
                  display={trackCollege.college.user_has_registered ? "initial" : "none"}
                  width="full"
                  colorScheme="green"
                  h="3px"
                  marginTop="-3px"
                  value={passedPercent}
                />
              </Flex>
            </AspectRatio>

            {!isMini && (
              <Text width="full" textAlign="right" whiteSpace="break-spaces" fontSize="md" fontWeight="700">
                {trackCollege.college.name}
              </Text>
            )}
          </VStack>
          <Stack w="full" pt={0} alignItems="start" justifyContent={{ base: "space-between" }}>
            {isMini && (
              <Text width="full" textAlign="right" whiteSpace="break-spaces" fontSize="md" fontWeight="700">
                {trackCollege.college.name}
              </Text>
            )}
            <Spacer flex={1} />
            <Flex marginTop={{ base: "4 !important" }} justifyContent="space-between" w="full" alignItems="center">
              <Text
                lineHeight="base"
                color={collegeLevelTextColor}
                fontSize={{ base: "xs", md: "sm" }}
                fontWeight="400"
                gap="2.5"
              >
                {trackCollege.level.title}
              </Text>
              {trackCollege.buy_state?.text ? (
                <BuyState buy_state={trackCollege.buy_state} />
              ) : (
                <SelectPriceComponent
                  price={trackCollege?.college?.price}
                  fix_discount_price={trackCollege?.college?.fix_discount_price}
                />
              )}
            </Flex>
          </Stack>
        </Stack>
      </LinkBox>
    </Card>
  );
};

interface TagTextProps extends BoxProps {
  content: string;
}

SelectPriceComponent.fragment = gql`
  fragment CollegePLPPriceTagFragment on CollegeObjectType {
    fix_discount_price
    price
  }
`;

BuyState.fragment = gql`
  fragment BuyStateFragment on TrackCollegeType {
    buy_state {
      colorScheme
      text
      icon
    }
  }
`;

CollegeCard.fragment = gql`
  fragment CollegeCardTrackCollegeFragment on TrackCollegeType {
    title
    ...BuyStateFragment
    tags
    college {
      pk
      name
      name_description
      url_name
      user_count
      has_enabled_new_landpage_for_registered_user
      tag_text
      card_image_url
      light_large_card_image_url
      dark_normal_card_image_url
      dark_large_card_image_url
      user_has_registered
      passed_percent
      ...CollegePLPPriceTagFragment
    }
    feature(first: 30) {
      edges {
        node {
          icon
          title
          id
        }
      }
    }
    level {
      id
      level
      title
    }
    tracks(first: 30) {
      edges {
        node {
          id
        }
      }
    }
  }
  ${BuyState.fragment}
  ${SelectPriceComponent.fragment}
`;
