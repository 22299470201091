import axios, { AxiosError } from "axios";
import { FieldValues, Path, useForm, UseFormProps, UseFormReturn } from "react-hook-form";
import { useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "next-i18next";

type onDrfErrorParams = {
  error: AxiosError;
  handleNonFieldErrors?: (errors: string[]) => any;
};
export type UseCreateFormReturn<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
  TServerData = any,
> = UseFormReturn<TFieldValues, TContext> & {
  serverData: TServerData;
  setServerData: React.Dispatch<React.SetStateAction<TServerData>>;
  getFieldSelectedOptions: any;
  setFieldSelectedOptions: any;
  toast: ReturnType<typeof useToast>;
  onClientSideError: () => void;
  onDrfError: (params: onDrfErrorParams) => void;
};

/**
 * This is useForm with extra sugar:
 * - Helper to store custom server data and set initial form data
 * - Helper to handle DRF errors
 */
export const useCreateForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
  TServerData = any,
>({
  useFormProps = {},
}: {
  useFormProps?: UseFormProps<TFieldValues, TContext>;
}): UseCreateFormReturn<TFieldValues, TContext, TServerData> => {
  const { t, i18n } = useTranslation("common");
  const toast = useToast();
  const [serverData, setServerData] = useState<TServerData>(null);
  const [_selectedOptions, _setSelectedOptions] = useState({});
  const form = useForm(useFormProps);

  const getFieldSelectedOptions = (field: string) => _selectedOptions[field];
  const setFieldSelectedOptions = (field: string, option: any) => {
    _setSelectedOptions((prev) => ({ ...prev, [field]: option }));
  };

  const genericErrorToast = () => {
    const description = t("لطفاً خطاها را تصحیح کنید.");
    if (!toast.isActive(description)) {
      toast({
        id: description,
        description,
        status: "error",
        containerStyle: { direction: i18n?.dir ? i18n?.dir() : "rtl" } as unknown,
      });
    }
  };

  const onDrfError = ({ error, handleNonFieldErrors = null }: onDrfErrorParams) => {
    if (toast && handleNonFieldErrors) throw new Error("You shouldn't pass both `toast` and `handleNonFieldErrors`");

    let usedToastForNonFieldErrors = false;

    const processNonFieldErrors = (errors: string[]) => {
      if (handleNonFieldErrors) {
        handleNonFieldErrors(errors);
      } else {
        errors.forEach((err) => {
          usedToastForNonFieldErrors = true;
          if (!toast.isActive(err)) {
            toast({ id: err, description: err, status: "error" });
          }
        });
      }
    };

    if (!axios.isAxiosError(error)) return;

    Object.entries(error.response.data).forEach(([key, errors]: [string, string[]]) => {
      if (key === "non_field_errors") {
        processNonFieldErrors(errors);
      } else if (Array.isArray(errors)) {
        // field errors
        errors.forEach((err) => form.setError(key as Path<TFieldValues>, { type: "manual", message: err }));
      } else if (typeof errors === "object") {
        // nested field errors
        Array.from(Object.entries(errors)).forEach(([nestedKey, nestedErrors]) => {
          if (Array.isArray(nestedErrors)) {
            nestedErrors.forEach((err) =>
              form.setError(`${key}.${nestedKey}` as Path<TFieldValues>, { type: "manual", message: err }),
            );
          }
        });
      } else if (key === "detail") {
        processNonFieldErrors([errors]);
      }
    });

    if (toast && !usedToastForNonFieldErrors) genericErrorToast();
  };

  return {
    ...form,
    serverData,
    setServerData,
    getFieldSelectedOptions,
    setFieldSelectedOptions,
    toast,
    onClientSideError: genericErrorToast,
    onDrfError,
  };
};
