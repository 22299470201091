import { DependencyList, useEffect, useRef, useState } from "react";

interface UseLongElementOptions {
  maxHeight?: number;
  callback?: () => void;
}

export const useLongElement = (
  { maxHeight = 84, callback }: UseLongElementOptions = {},
  dependencies: DependencyList = [],
) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isLong, setIsLong] = useState<boolean>(true);

  useEffect(() => {
    setIsLong(ref.current !== null && ref.current.scrollHeight > maxHeight);
    if (callback) callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, maxHeight, ...dependencies]);

  return { ref, isLong, maxHeight };
};
